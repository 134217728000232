import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/investing-in-rental-properties-in-san-diego";

  return (
    <LayoutWrapper
      title="Investing In Rental Properties In San Diego | The Selby Team"
      desc="Thinking of investing in San Diego real estate? Read our blog for valuable information for prospective investors, covering key considerations, market trends & practical tips!"
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              Investing In Rental Properties In San Diego | The Selby Team
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By The Selby team
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">Dec 6, 2023</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Investing In Rental Properties In San Diego_ What You Need To Know.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The mild weather, proximity to the ocean, and the blend of bustling nightlife and
                tranquil, family-friendly neighborhoods have long made{" "}
                <a href="https://selbysellssd.com/" target="_Blank" className="text-blue-500">
                  San Diego
                </a>{" "}
                a highly coveted destination for renters and investors.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                While it's an exceptionally desirable place to live, investors may wonder if the
                city is still a viable choice for rental property ventures. While we advise you to
                speak with a financial advisor before deciding, here's what we know about the rental
                market.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                It’s a Landlord Market
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Not just the climate and lifestyle amenities make San Diego a solid rental market.
                It’s also that the city has limited supply due to geographical constraints, zoning
                regulations, and long permit processes. This limited supply (relative to demand)
                puts landlords in a favorable position.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Beyond that, the combination of low supply and strong demand continues pushing
                rental prices up, allowing landlords to charge higher rents—another substantial
                benefit for investors.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Neighborhoods to Watch
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                When investing in rental properties in San Diego, choosing the right neighborhood is
                critical. Here are a few areas to keep an eye on:
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <a
                  href="https://selbysellssd.com/san-diego-communities/north-park-homes-for-sale"
                  target="_Blank"
                  className="text-blue-500"
                >
                  North Park
                </a>
                : North Park is known for its eclectic atmosphere, diverse community, and thriving
                arts and dining scene. It offers a range of housing options, including single-family
                homes and multi-unit properties, making it accessible to a broad spectrum of
                renters. The neighborhood's relative affordability (compared to beachfront areas)
                makes it an appealing choice for investors looking to enter the San Diego market
                without the high entry costs.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <a
                  href="https://selbysellssd.com/san-diego-communities/south-park-homes-for-sale"
                  target="_Blank"
                  className="text-blue-500"
                >
                  South Park
                </a>
                : Neighboring South Park shares similar advantages. Investors can find a variety of
                property types here, and the rental market remains relatively stable, making it a
                reliable choice for those seeking consistent rental income.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <strong>Sorrento Valley:</strong> Sorrento Valley has a near proximity to the
                Miramar military base, ensuring a steady influx of military personnel looking for
                housing options. It's also worth mentioning that Sorrento is home to numerous
                biotech companies, which continue to attract young professionals and families.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Familiarize Yourself With AB 1482
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you’re unfamiliar with the 2019{" "}
                <a
                  href="https://www.socalrha.org/ab-1482----rent-caps-and-just-cause"
                  target="_Blank"
                  className="text-blue-500"
                >
                  AB 1482
                </a>
                , you should know it has added a layer of complexity to property management and
                rental regulations. We want to emphasize that this law should not discourage you
                from investing in San Diego; instead, we aim to provide you with information about
                the law so you can plan accordingly.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                AB 1482: The Specifics
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                AB 1482 imposes rent increases to no more than 5% (adjusted for inflation) or 10%
                annually—whichever is lower. Additionally, landlords must have “just cause” to
                terminate a tenancy. Here’s a breakdown of the specifics:
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <strong>Timing:</strong> Under AB 1482, the “just cause” eviction provisions come
                into effect after 12 months of a tenant's occupancy, as opposed to the standard San
                Diego Just Cause Ordinance, which applies after two years.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <strong>Categories:</strong> The just cause eviction reasons under AB 1482 are
                categorized into: "At Fault" and "No Fault."
                <ul className="list-disc px-[20px] text-xs pt-[10px]">
                  <li>
                    <strong>At Fault Reasons:</strong> These are reasons for eviction where the
                    tenant has done something wrong or violated the lease terms. Examples include
                    non-payment of rent, lease breaches, criminal activity, and refusal to provide
                    access.
                  </li>
                  <li>
                    <strong>No-Fault Reasons:</strong> These are reasons for eviction that don't
                    involve tenant wrongdoing. Examples include the landlord's intent to demolish or
                    remodel the property, withdrawing the property from the rental market, owner or
                    relative occupancy, or government orders.
                  </li>
                </ul>
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <strong>Eligibility:</strong> AB 1482 originally applied to multifamily units that
                were over 15 years old. However, this threshold changes yearly, meaning the law
                applies to properties built in specific years.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                For example, in one year, it may apply to properties built in 2005 or earlier, and
                in the next year, it might apply to properties built in 2006 or earlier. It also
                applies to properties that a corporation wholly or partly owns. Most other
                properties are exempt from these specific AB 1482 provisions.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Thinking About Investing in San Diego?
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Are you thinking about investing in{" "}
                <a
                  href="https://selbysellssd.com/buy-house-san-diego"
                  target="_Blank"
                  className="text-blue-500"
                >
                  San Diego real estate
                </a>
                ? Explore the possibilities with the assistance of The Selby Team.{" "}
                <a
                  href="https://selbysellssd.com/san-diego-real-estate-agents"
                  target="_Blank"
                  className="text-blue-500"
                >
                  Our experts
                </a>{" "}
                have insider expertise, in-depth market knowledge, and are here to guide you in
                making well-informed investment choices.{" "}
                <ContactSlideoutLink text="Contact us now" />!
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
